
import ManageLayout from '@/components/layouts/ManageLayout.vue';

export default [
    {
        path: '/manageDonate',
        component: ManageLayout,
        redirect:'/manageDonate/list',
        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"奉獻管理",
            }
        },
        children: [
            {
                path: 'overview',
                name: 'donate/overview',
                component: () => import('@/views/donate/DonateOverviewPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'donation',
                    permissions:['donation.#.view'],
                    seo:{
                        title:"奉獻總覽",
                    }
                },
            },
            {
                path: 'list',
                name: 'donate/list',
                component: () => import('@/views/donate/DonateListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'donation',
                    permissions:['donation.#.edit'],
                    seo:{
                        title:"奉獻列表",
                    }
                },
            },
            {
                path: 'form',
                name: 'donate/form',
                component: () => import('@/views/donate/DonateFormBuilderPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'donation',
                    permissions:['donation.#.edit'],
                    seo:{
                        title:"奉獻表單",
                    }
                },
            },
            {
                path: 'withdraw',
                name: 'donate/withdraw',
                component: () => import('@/views/donate/DonateWithdrawPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'donation',
                    permissions:['donation.#.edit'],
                    seo:{
                        title:"奉獻提領與帳務",
                    }
                },
            },
            {
                path: 'cashDonate/list',
                name: 'cashDonate/list',
                component: () => import('@/views/cashDonate/CashDonatePage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'donation',
                    permissions:['donation.#.edit'],
                    seo:{
                        title:"現金帳列表",
                    }
                },
            },
            {
                path: 'taxReport',
                name: 'taxReport',
                component: () => import('@/views/donate/TaxReportGeneratePage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'donation',
                    permissions:['donation.#.edit'],
                    seo:{
                        title:"國稅局報表匯出",
                    }
                },
            },
            {
                path: 'receiptReport',
                name: 'receiptReport',
                component: () => import('@/views/donate/ReceiptGeneratePage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'donation',
                    permissions:['donation.#.edit'],
                    seo:{
                        title:"收據生成",
                    }
                },
            },
        ]
    },
   
];